<template>
  <div>
    <table class="table table-google">
      <tbody>
        <tr>
          <td class="row-fit">ID</td>
          <td>
            <span v-if="herbid">{{ herb.Id }}</span>
            <span v-else>New</span>
          </td>
        </tr>

        <tr>
          <td>Name</td>
          <td>
             <b-form-input
              v-model="herb.Name"
              placeholder="Plant"
              size="sm"
              :disabled="loadingCount > 0"
              autocomplete="off"
            >
            </b-form-input>
          </td>
        </tr>

        <tr>
          <td>Group</td>
          <td>
            <b-form-select
              v-model="herb.GroupId"
              :options="options.groupid"
              size="sm"
              text-field="Name"
              value-field="Id"
              :disabled="loadingCount > 0"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <b-button
        variant="primary"
        size="sm"
        @click="saveHerb()"
        :disabled="loadingCount > 0"
      >
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HerbEdit',
  components: {},
  props: {
    herbid: Number,
  },
  data() {
    return {
      loadingCount: 0,
      herb: {
        Id: null,
        GroupId: null,
        Name: null,
      },
      groups: [],
      options: {
        groupid: [],
      },
    };
  },
  methods: {
    fetchHerb() {
      if (!this.herbid) {
        return;
      }
      const params = {
        herbids: [this.herbid],
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herbs' })
        .send({ params })
        .then((res) => {
          const [herb] = res.body;
          this.herb = herb;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveHerb() {
      const params = {
        name: this.herb.Name,
        groupid: this.herb.GroupId,
      };
      if (this.herbid) {
        params.herbid = this.herb.Id;
      }
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'herb' })
        .send({ params })
        .then((res) => {
          if (isNaN(this.herbid)) {
            this.$emit('herbid', res.body);
          }
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchGroups() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'groups' })
        .then((res) => {
          this.options.groupid = [
            { Id: null, Name: '' },
            ...res.body,
          ];
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchHerb();
      this.fetchGroups();
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
</style>
